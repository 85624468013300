<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { useStockApi } from "@/apis/stock";
import { useSystemApi } from "@/apis/system";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '入库记录审批',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead,
    flatPickr

  },
  data() {
    return {
      tableData: [],
      model_data: [],
      storageList: [],
      title: '入库申请审批',
      items: [
        {
          text: this.$t('stock.list.nav_first_item'),
          href: "/",
        },
        {
          text: '入库申请审批',
          active: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 30,

      fields: [

        {
          key: "checkbox",
          label: '',
          sortable: true,
        },

        {
          key: "stock_model",
          label: '型号',
          sortable: true,
        },

        {
          key: "model_name",
          label: '名称',
          sortable: true,
        },

        {
          key: "stock_count",
          label: '数量',
          sortable: true,
        },

        {
          key: "stock_type",
          label: '类型',
          sortable: true,
        },

        {
          key: "stock_date",
          label: '日期',
          sortable: true,
        },



        {
          key: "operator",
          label: '申请人',
          sortable: true,
        },


      ],

      p_model: '',
      currentStorage: '',
      dateStart: '',
      dateEnd: '',
      c_stock_remarks: '',
      selected_p_list: []
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },


  },
  mounted() {

    this.query_storage()
    this.query_stock_record()

  },
  methods: {

    query_stock_record() {
      let data = {
        stock_category: 'PENDING_LIST',
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        }
      }

      if (this.p_model != '') {
        data.model = this.p_model
        data.page.page_no = 1
      }


      if (this.dateStart != '') {
        data.date_start = this.dateStart
      }

      if (this.dateEnd != '') {
        data.date_end = this.dateEnd
      }

      useStockApi().query_stock_record_detail_query(data).then(res => {
        if (res.err == 0) {
          this.tableData = []
          res.data.map(e => {
            this.tableData.push(e)
          })

          this.totalRows = res.page.total_count
        }
      })
    },

    query_storage() {
      useSystemApi().list_system_conf('STORAGE').then(res => {
        if (res.err == 0) {

          res.data.map(e => {

            this.storageList.push({
              code: e.key_field,
              name: e.value_field,
              remarks: e.remarks,
              id: e.id,
              value: e.key_field,
              label: e.value_field
            })
          })
        }

        this.currentStorage = this.storageList[0].value
        this.storageList[0].selected = true
        new Choices('#storage_list', { choices: this.storageList })
      })
    },


    confirm_stock() {
      let data = {
        storage      : this.currentStorage,
        produces     : this.selected_p_list,
        remarks      : this.c_stock_remarks
      }
      useStockApi().approve_stock(data).then(res => {
        if (res.err == 0) {
          this.$alertify.message("入库成功");
          this.query_stock_record();
          this.$bvModal.hide('confirm_diloag')
        } else {
          this.$alertify.error("确认入库失败, 错误码:"+ res.err +'  信息：'+res.msg);
        }
      })
    },


    discard_stock() {

      this.$alertify.confirmWithTitle('确认',
        "确定要废弃入库申请吗？ ！！！ 此申请记录无法恢复！！！！",
        () => {
          let data = {
            storage      : this.currentStorage,
            produces     : this.selected_p_list,
            remarks      : this.c_stock_remarks
          }
          useStockApi().discard_stock(data).then(res => {
            if (res.err == 0) {
              this.$alertify.message("废弃入库申请成功");
              this.query_stock_record();
              this.$bvModal.hide('confirm_diloag')
            } else {
              this.$alertify.error("废弃入库申请失败, 错误码:"+ res.err +'  信息：'+res.msg);
            }
          })
        },
        () => {

        }
      );
    },

    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }

      })
    },



    pageChange(p) {
      this.currentPage = p
      this.query_stock_list()
    },


    showPrepareDialog() {
      if (this.selected_p_list.length <= 0) {
        this.$alertify.error("请选择入库型号");
        return 
      }

      this.$bvModal.show('confirm_diloag')
    },

    onDetailUpdate(evt, p) {

      if (evt.target.checked == false) {
        this.selected_p_list = this.selected_p_list.filter(e => e.id != p.id)
      } else {
        let cache = this.selected_p_list.find(e => e.id == p.id)
        if (!cache) {
          p.model   = p.stock_model
          p.number  = p.stock_count
          p.p_number= p.pnumber
          this.selected_p_list.push(p)
        } 
      }
    }


  },

  watch: {
    p_model: function (new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="confirm_diloag" centered title="入库确认" title-class="text-black font-18" body-class="p-3" size="lg"
      hide-footer hide-header-close :static="true">
      <div class="row mb-3">

        <div class="col-md-3">
          <label class="form-label">仓库选择</label>
          <select class="form-control" id="storage_list" v-model="currentStorage">
          </select>
        </div>

        <div class="col-md-6">
          <label class="form-label">入库说明</label>
          <input type="text" class="form-control" v-model="c_stock_remarks" maxlength="100" />
        </div>

      </div>



      <div class="table-responsive mb-0">
        <b-table class="datatables" :items="selected_p_list" :fields="fields" responsive="sm" :per-page="selected_p_list.length">
          <template #cell(checkbox)>
            <input type="checkbox" checked :disabled="true">
          </template>
          <template #cell(stock_type)>
            {{ '入库申请' }}
          </template>

          <template #cell(stock_date)="data">
            {{ data.item.stock_record.stock_date }}
          </template>

          <template #cell(operator)="data">
            {{ data.item.stock_record.operator }}
          </template>

        </b-table>
      </div>

      <div class="row">
        <div class="mb-3">
          <div class="col-sm-24 col-md-12">
            <div class=" d-flex flex-wrap  justify-content-md-end gap-2">

              <b-button variant="secondary" @click="$bvModal.hide('confirm_diloag')">取消</b-button>
              <b-button variant="primary" @click="confirm_stock">确认入库</b-button>
              <b-button variant="danger" @click="discard_stock">废弃入库</b-button>
            </div>
          </div>
        </div>
      </div>


    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-2">

                <div class="row">
                  <div class="col-md-3">
                    <label class="d-inline-flex align-items-center"> 型号选择&nbsp;</label>
                  </div>
                  <div class="col-md-8">
                    <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off" :inputClass="{}" />
                  </div>

                </div>


              </div>
              <div class="col-sm-8 col-md-4">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 日期&nbsp;</label>
                  </div>
                  <div class="col-md-4">
                    <flat-pickr placeholder="Select a date" class="form-control" v-model="dateStart"></flat-pickr>
                  </div>
                  <div class="col-md-2"><input type="checkbox" class="form-check">
                    <label class="d-inline-flex align-items-center"> 区间选择&nbsp;</label>
                  </div>
                  <div class="col-md-4">
                    <flat-pickr placeholder="Select a date" class="form-control" v-model="dateEnd"></flat-pickr>
                  </div>


                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
                  <b-button variant="primary" @click="showPrepareDialog">准备入库</b-button>
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-list' })">返回</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>


            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage">
                <template #cell(checkbox)="data">
                  <input type="checkbox" @change="onDetailUpdate($event, data.item)"/>
                </template>
                <template #cell(stock_type)>
                  {{ '入库申请' }}
                </template>

                <template #cell(stock_date)="data">
                  {{ data.item.stock_record.stock_date }}
                </template>

                <template #cell(operator)="data">
                  {{ data.item.stock_record.operator }}
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @change="pageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
